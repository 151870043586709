import React, { useState } from "react"
import axios from "axios"
import { useForm } from "react-hook-form"
import SEO from "../components/seo"
import Footer from "../components/common/Footer"
import Navbar from "../components/common/Navbar"
import Alert from "../components/common/Alert"
import "./index.css"

const ContactPage = () => {
  const [status, setStatus] = useState("INITIALIZED")
  const { register, handleSubmit } = useForm()

  const onSubmit = async ({ name, email, phone, about }) => {
    setStatus("SUBMITTING")
    try {
      await axios.post("/api/sendEmail", { name, email, phone, about })
      setStatus("SUCCESS")
    } catch (e) {
      console.error("Unable to submit contact information", e)
      setStatus("ERROR")
    }
  }
  const isSubmitting = status === "SUBMITTING"
  return (
    <div className="flex flex-col min-h-screen bg-white md:bg-gray-100">
      <SEO
        pageTitle="Contact Us"
        ogTitle="Let's get in touch"
        pathname="/contact"
      />
      {status === "ERROR" && (
        <Alert
          onClose={() => setStatus("INITIALIZED")}
          message="We were unable to submit your contact information at this time."
          type="ERROR"
        />
      )}
      <Navbar />
      <main className="flex-grow">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full max-w-4xl p-4 mx-auto my-8 bg-white rounded-md">
            <h1 className="mb-4 ml-4 text-3xl font-thin">Contact Us</h1>
            {status === "SUCCESS" ? (
              <section className="grid grid-cols-1 my-8">
                <div>
                  <p className="mt-1 text-xl leading-7 text-indigo-800">
                    Thank you for your interest. We'll be in touch shortly!
                  </p>
                </div>
              </section>
            ) : (
              <>
                <section className="grid grid-cols-1 my-8">
                  <div>
                    <p className="mt-1 text-xl leading-7 text-gray-500">
                      Tell us how to contact you and we'll be in touch!
                    </p>
                  </div>
                  <div className="grid grid-cols-1 row-gap-6 col-gap-4 mt-6 sm:grid-cols-6">
                    <div className="sm:col-span-6">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Name
                      </label>
                      <div className="mt-1 rounded-md shadow-sm md:w-1/2">
                        <input
                          id="name"
                          name="name"
                          ref={register}
                          disabled={isSubmitting}
                          className="block w-full transition duration-150 ease-in-out form-input sm:text-sm sm:leading-5"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-6">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Email
                      </label>
                      <div className="mt-1 rounded-md shadow-sm md:w-1/2">
                        <input
                          id="email"
                          name="email"
                          ref={register}
                          disabled={isSubmitting}
                          type="email"
                          className="block w-full transition duration-150 ease-in-out form-input sm:text-sm sm:leading-5"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-6">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Phone
                      </label>
                      <div className="mt-1 rounded-md shadow-sm md:w-1/2">
                        <input
                          id="phone"
                          name="phone"
                          ref={register}
                          disabled={isSubmitting}
                          className="block w-full transition duration-150 ease-in-out form-input sm:text-sm sm:leading-5"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-6">
                      <label
                        htmlFor="about"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        How can we help?
                      </label>
                      <div className="mt-1 rounded-md shadow-sm md:w-3/4">
                        <textarea
                          id="about"
                          name="about"
                          ref={register}
                          disabled={isSubmitting}
                          rows="3"
                          className="block w-full transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5"
                        ></textarea>
                      </div>
                      <p className="mt-2 text-sm text-gray-500">
                        Are you looking to setup your own fundraiser? Or maybe
                        you have a product you'd like to sell? Let us know!
                      </p>
                    </div>
                  </div>
                </section>
                <section className="grid grid-cols-1 mt-8 md:grid-cols-3">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                  >
                    Submit
                  </button>
                </section>
              </>
            )}
          </div>
        </form>
      </main>
      <Footer />
    </div>
  )
}

export default ContactPage
