import React from "react"

const CheckMarkIcon = () => (
  <svg
    className="w-6 h-6 text-green-400"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
)

const ErrorIcon = () => (
  <svg className="w-5 h-5 text-red-400" fill="currentColor" viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
      clipRule="evenodd"
    />
  </svg>
)

const Alert = ({ onClose, type, message, actionText, onAction }) => (
  <div className="fixed inset-0 z-20 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
    <div
      role="status"
      aria-live="polite"
      aria-atomic="true"
      className="w-full max-w-sm bg-white rounded-lg shadow-lg pointer-events-auto"
    >
      <div className="overflow-hidden rounded-lg shadow-xs">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              {type === "SUCCESS" && <CheckMarkIcon />}
              {type === "ERROR" && <ErrorIcon />}
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5 flex justify-between">
              <p className="text-sm font-medium leading-5 text-gray-900">
                {message}
              </p>
              {actionText && (
                <button
                  onClick={onAction}
                  className="flex-shrink-0 ml-3 text-sm font-medium leading-5 text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:outline-none focus:underline"
                >
                  {actionText}
                </button>
              )}
            </div>
            <div className="flex flex-shrink-0 ml-4">
              <button
                onClick={onClose}
                aria-label="Close Alert"
                className="inline-flex text-gray-400 transition duration-150 ease-in-out focus:outline-none focus:text-gray-500"
              >
                <svg
                  className="w-5 h-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Alert
